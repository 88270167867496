import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Outsource from "../components/e-Commerce/Outsource"
import AccordianTech from "../components/educational-soofware/AccordianTech"
import Banner from "../components/educational-soofware/Banner"
import Benefits from "../components/educational-soofware/Benefits"
import Explore from "../components/educational-soofware/Explore"
import HireDevelopers from "../components/educational-soofware/HireDevelopers"
import Idea from "../components/educational-soofware/Idea"
import LatestFeatures from "../components/educational-soofware/LatestFeatures"
import Process from "../components/educational-soofware/Process"
import Solutions from "../components/educational-soofware/Solutions"
import Technologies from "../components/educational-soofware/Technologies"
import MainLayout from "../layouts/MainLayout3"

const educational = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const tech = [data?.strapiPage?.sections[7], data?.strapiPage?.sections[8]]

  const heroSection = data?.strapiPage?.sections[0]
  const explore = data?.strapiPage?.sections[1]
  const idea = data?.strapiPage?.sections[2]
  const solutions = data?.strapiPage?.sections[3]
  const process = data?.strapiPage?.sections[4]
  const technologies = data?.strapiPage?.sections[5]
  const outsource = data?.strapiPage?.sections[6]
  const benefits = data?.strapiPage?.sections[7]
  const features = data?.strapiPage?.sections[8]
  const hire = data?.strapiPage?.sections[9]
  const ourWork = data?.strapiPage?.sections[10]

  const faqs = data?.strapiPage?.sections[11]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Explore strapiData={explore} />
      <Idea strapiData={idea} />
      <Solutions strapiData={solutions} />
      <Process strapiData={process} />
      {!isMobile && !isTablet ? (
        <Technologies strapiData={technologies} />
      ) : (
        <AccordianTech strapiData={technologies} />
      )}

      <Outsource strapiData={outsource} pageName="Education" />
      <Benefits strapiData={benefits} />
      <LatestFeatures strapiData={features} />
      <HireDevelopers strapiData={hire} />
      <WorkPortfolio strapiData={ourWork} />
      <Faqs strapiData={faqs} pageName="Education" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "education-1" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default educational

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
    />
  )
}
